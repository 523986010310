<template>
  <div>
    <DepartureProcess />
    <router-view></router-view>
  </div>
</template>

<script>
import DepartureProcess from '@/views/package-order/departure-process/DepartureProcess.vue'

export default {
  name: 'DepartureProcessIndex',
  components: {
    DepartureProcess
  },
  mounted() {
    console.log('mounted departure')
  }
}
</script>

<style>

</style>
