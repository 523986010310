
import {
  ref,
  onMounted,
  getCurrentInstance,
  reactive,
  computed,
  watch,
  toRefs
} from 'vue'

export default {
  name: 'DialogDetailResiX',
  props: {
    dataSource: {
      default: () => Array
    }
  },
  emits: ['hideDialog', 'reloadData', 'processScanResi'],
  setup(props: any, {
    emit
  }: any) {
    const app = getCurrentInstance()
    const {
      $toast,
      $confirm
    } = app!.appContext.config.globalProperties
    const hidedialogDetailResi = () => {
      // dialogDetailResi.value = false
      emit('hideDialog')
    }
    const processScanResi = (guid: any) => {
      // dialogDetailResi.value = false
      emit('processScanResi', guid)
    }
    const dialogDetailResi = ref(true)

    return {
      hidedialogDetailResi,
      dialogDetailResi,
      processScanResi
    }
  }
}
