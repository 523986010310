import {
  ref,
  reactive,
  watch,
  toRefs,
  getCurrentInstance,
  onMounted
} from 'vue'
import {
  useStore
} from 'vuex'
import {
  environment
} from '@/utils'
import { cloneDeep } from 'lodash'
import { useRoute } from 'vue-router'
import moment from 'moment'

export default {
  name: 'CardReadyDeliver',
  props: {
    /** Atribute untuk object data untuk ditampilkan pada card */
    data: {
      default: () => Object
    },
    /** Attribute untuk mendapatkan index dri looping */
    index: {
      default: () => Number
    },
    useSelectQourier: {
      default: () => true
    },
    isUseScheduleBtn: {
      default: () => false
    },
    /** Attribute yang digunakan untuk mendapatkan list courier */
    courierOptions: {
      default: () => Array
    }
  },
  emits: ['settingSchedule', 'changeCourier', 'showModalChangeCourier', 'packageReturn', 'transferCourier', 'toManifest', 'showDetailResiDialog'],
  setup(props: any, ctx: any) {
    const courierBy = ref(null)
    const app = getCurrentInstance()
    const {
      $toast,
      $numbers
    } = app!.appContext.config.globalProperties
    const store = useStore()
    const route = useRoute()
    const {
      emit
    } = ctx
    // const {
    //   data
    // } = reactive(props)
    // eslint-disable-next-line prefer-destructuring
    const data = toRefs(props).data
    const { courierOptions } = toRefs(props)
    const index = toRefs(props).index as any
    const checking = ref(true)
    const op = ref()
    const inputSelectCourier = ref()
    const panelListCourier = ref()
    const panelListChangeCourier = ref()
    const API_BASE = environment.getApiBase()

    const scheduleButtonAct = (selectedData: any) => {
      console.log('di card', selectedData)
      emit('settingSchedule', selectedData)
    }
    const toManifestAct = (selectedData: any) => {
      console.log('di card', selectedData)
      emit('toManifest', selectedData)
    }
    const selectCourier = (newSubsCourier: any) => {
      const newDataCourier = cloneDeep(data.value) as any
      newDataCourier.KurirPengganti = newSubsCourier
      emit('transferCourier', index.value, [newDataCourier], 'transfer-kurir')
      panelListCourier.value.hide()
    }
    const packageReturn = () => {
      emit('packageReturn', [data.value], index.value)
    }

    const changeCourier = (event: any) => {
      // panelListCourier.value.toggle(evt)
      op.value.hide()
      panelListCourier.value.toggle(event)
      // showPanelListCourier(event)
    }

    const showDropDown = (evt: any) => {
      op.value.toggle(evt)
    }

    /** Fungsi emit change dri MultiSelect dengan
     * params yang berisi Id Kurir yang telah dipilih */
    const onChange = (el: any) => {
      console.log('kurirId', [el, data.value.Kurir])
      data.value.KurirId = el.Id
      const lastIdKurir = data.value.KurirId
      if (el === null) {
        data.value.KurirId = lastIdKurir
        $toast.add({
          severity: 'error',
          detail: 'Data tidak boleh kosong',
          group: 'bc',
          life: 1500
        })
      } else {
        /** Kirim emit ke parent component dengan params yang berisi
         * 1. Id Kurir yang dipilih,
         * 2. IdPaket yang telah dipilih, dan
         * 3. index dri list yang dipilih
         * */
        emit('changeCourier', el.Id, data.value.IdPaket, index.value)
      }
    }

    const onTouch = (val: any) => {
      console.log('on close', val)
    }

    const onClickSelect = (event: any) => {
      panelListChangeCourier.value.toggle(event)
    }

    const selectPickupCourier = (val: any) => {
      const newDataCourier = cloneDeep(data.value) as any
      newDataCourier.KurirPengganti = val
      emit('transferCourier', index.value, [newDataCourier], 'ganti-kurir')
      console.log('newData courier', newDataCourier)
    }

    const showDetailResiDialogAct = (val: any) => {
      console.log('aaa')
      emit('showDetailResiDialog', val)
    }

    const initCourierOptions = () => {
      console.log('data', data.value)
      console.log('courierOptions', courierOptions.value)
    }

    onMounted(() => {
      initCourierOptions()
    })

    return {
      courierBy,
      checking,
      /* eslint-disable vue/no-dupe-keys */
      data,
      scheduleButtonAct,
      // courierOptions,
      selectCourier,
      index,
      onChange,
      onTouch,
      API_BASE,
      showDropDown,
      op,
      changeCourier,
      packageReturn,
      panelListCourier,
      inputSelectCourier,
      onClickSelect,
      panelListChangeCourier,
      selectPickupCourier,
      route,
      moment,
      toManifestAct,
      showDetailResiDialogAct
    }
  }
}
