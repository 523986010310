/* eslint-disable no-await-in-loop */
/* eslint-disable no-self-assign */
/* eslint-disable prefer-destructuring */
import {
  ref, watch, onMounted, getCurrentInstance, reactive, onBeforeUnmount
} from 'vue'
import CardReadyDeliver from '@/views/package-order/new-package/ready-deliver/CardReadyDeliver.vue'
import {
  departureProcessUseCase,
  newPackageUseCase
} from '@/domain/usecase'
import { useStore } from 'vuex'
import { VueEternalLoading, LoadAction } from '@ts-pro/vue-eternal-loading'
import DialogDetailResiX from '@/views/package-order/departure-process/DepatureProcessReceiptDetail.vue'
import moment from 'moment'
import { useRouter } from 'vue-router'
import { encryptDecriptMethods } from '@/plugins/encryptDecriptMethods'

export default {
  name: 'DepartureProccess',
  components: {
    CardReadyDeliver,
    VueEternalLoading,
    DialogDetailResiX
  },
  setup() {
    const app = getCurrentInstance()
    const store = useStore()
    const router = useRouter()
    const {
      $toast, $numbers, $socketHub
    } = app!.appContext.config.globalProperties
    const orderOption = ref([
      {
        label: 'Waktu Order Terbaru',
        value: 'TransPengirimanHd/LastUpdate desc'
      },
      {
        label: 'Resi',
        value: 'TransPengirimanHd/Resi asc'
      },
      {
        label: 'Kategori Barang',
        value: 'TransPengirimanHd/KategoriBarang asc'
      }
      // {
      //   label: 'Kurir Penjemput',
      //   value: 'KurirMsUser/Fullname desc'
      // },
      // {
      //   label: 'Barang Dibawa Kurir',
      //   value: 'IsActive desc'
      // },
      // {
      //   label: 'Barang Belum Discan',
      //   value: 'IsActive asc'
      // }
    ])
    const dataSource = ref([]) as any
    const dataSourceModal = ref([]) as any
    const dataForm = ref(null)
    const showDialogDetail = ref(false)
    const inputSearch = ref(null)
    const inputSearchReceipt = ref(null)
    const orderBy = ref('TransPengirimanHd/LastUpdate desc')
    const listSchedule = ref<any>([])
    const noResult = ref(false)
    const message = ref(null) as any
    const tempData = ref([]) as any /** Variable ini digunakan untuk menampung data sementara dari list variable dataSource */
    const profileAgent = ref(store.getters['appActiveUser/getAgent'])
    const filters = reactive({
      skip: 0,
      top: $numbers.totalItemPerPage,
      totalRecords: 0,
      search: '',
      custom: `IsDone eq true and KurirTipePengirimanId eq 0 AND TransPengirimanHd/StatusPengiriman/Kode eq 'TDA' and (TransPengirimanHd/AgenAsalId eq ${profileAgent.value.id} or (TransPengirimanHd/AgenAsal/KotaId eq ${profileAgent.value.kotaId} or TransPengirimanHd/AgenAsal/Kota/MsGroupKotaId ne null) and (TransPengirimanHd/AgenAsal/Kota/MsGroupKotaId eq ${profileAgent.value?.cityGroupId}))`,
      select: '&$select=Id,TransPengirimanHdId,KurirTipePengirimanId,KurirMsUserId,IsActive',
      expand: '&$expand=KurirMsUser($select=Username), TransPengirimanHd($expand=TransJadwalHd, TransStockLabels($select=KodeLabel;$expand=Label($select=Nama,Warna)), StatusPengiriman($select=Id,Status,Kode), AgenAsal($select=Nama), AgenTujuan($select=Nama), TransPengirimanHdBiayaTambahans($expand=MsBiayaTambahan);$select=Id,AgenAsalId,AgenTujuanId,AlamatPenerima,AlamatPengirim,HpPenerima,HpPengirim,JenisPembayaran,Resi,TipePengiriman,TotalBerat,TotalColly,LastUpdate,KategoriBarang,NamaPengirim,StatusPengirimanId,TransJadwalHdId,KeteranganBiayaTambahan)',
      sorting: ''
    })
    const isInitial = ref(false)/** variable yang digunakan untuk mereset state menggunakan plugin vue-eternal-loading */

    /** Handling untuk List yang dicentang */
    const handleIsCheckInTempData = () => {
      tempData.value = []
      for (let index = 0; index < dataSource.value.length; index++) {
        if (dataSource.value[index]) {
          if (dataSource.value[index].IsCheck) {
            tempData.value.push(dataSource.value[index])
          }
        }
      }
    }

    /** Pengecekan jika dataSource mempunyai Id yang sama dengan tempData
     * maka Object di dataSource akan dihapus
     */
    const pushTempDataInDataSource = (data: any) => {
      let same = false
      for (let index = 0; index < dataSource.value.length; index++) {
        if (dataSource.value[index].Id === data.Id) {
          same = true
          break
        }
      }
      if (!same) {
        dataSource.value.unshift(data)
      }
    }
    /** Memindahkan data temporary yang tersimpan ke posisi atas */
    const addTempDataOnTop = () => {
      for (let index = 0; index < tempData.value.length; index++) {
        pushTempDataInDataSource(tempData.value[index])
      }
    }

    // Pengecekan untuk data yang sama di dalam list
    const checkTheSameData = (data: any) => {
      for (let index = 0; index < tempData.value.length; index++) {
        if (data.Id === tempData.value[index].Id) {
          return tempData.value[index]
        }
      }
      return data
    }

    /** Pengecekan antara data source dengan data temporary
     * jika sama akan dilakukan push data ke variabel dataSource
     */
    const checkPushInDataSource = (data: any) => {
      let same = false
      for (let index = 0; index < dataSource.value.length; index++) {
        if (dataSource.value[index]) {
          if (dataSource.value[index].Id === data.Id) {
            same = true
          }
        }
        if (same) {
          break
        }
      }
      if (!same) {
        if (data.IsCheck) {
          dataSource.value.unshift(data)
        } else {
          dataSource.value.push(data)
        }
      }
    }

    /** Function mendapatkan list data dri db */
    const tempLengthOfList = ref(0)
    const getAllReadyDeliver = async (reset = false) => {
      // await store.dispatch('showLoading')
      const response = await newPackageUseCase.getAll(filters)
      if (!response.error) {
        if (reset) {
          dataSource.value = []
          // isInitial.value = true
        }
        tempLengthOfList.value = response.result.length
        /** Mapping list barang sesuai dengan kebutuhan */
        response.result.forEach((x: any, index: any) => {
          const IdKurir = x.KurirMsUserId ? x.KurirMsUserId : null
          const IdPaket = x.Id
          const IsActive = x.IsActive
          const Kurir = null
          x = x.TransPengirimanHd
          // console.log('xxx', x.TransPengirimanHdBiayaTambahans)
          // console.log()
          x.Kurir = Kurir
          x.IdPaket = IdPaket
          x.KurirId = IdKurir
          x.IsActive = IsActive
          x.IsCheck = false
          x.File = []
          x.SumBiayaTambahan = x.TransPengirimanHdBiayaTambahans.reduce((a: any, b: any) => a + (b.Nominal || 0), 0)
          // console.log('x', x)
          /** Sending ke function checkTheSameData untuk pengecekan Id yang sama */
          const mapData = checkTheSameData(x)
          /** Pushing ke variable yang digunakan untuk menampilkan listnya */
          checkPushInDataSource(mapData)
        })
        addTempDataOnTop()
        filters.skip += filters.top
        filters.totalRecords = response.count
      } else {
        noResult.value = true
        message.value = 'Data Tidak Ada'
      }
      // await store.dispatch('hideLoading')
    }

    /** Fungsi emit dri child FormAssigCourier */
    const reloadData = async () => {
      store.dispatch('showLoading')
      // showCard.value = false
      filters.skip = await 0
      filters.top = await 0
      await getAllReadyDeliver(true)
      filters.top = $numbers.totalItemPerPage
      isInitial.value = await true
      store.dispatch('hideLoading')
      // showCard.value = true
    }

    /** Menggunakan load data untuk plugin pagination */
    const loadData = async ({ loaded, noMore }: LoadAction) => {
      getAllReadyDeliver(isInitial.value).then((x: any) => {
        if (tempLengthOfList.value < filters.top) {
          /** menggunakan fungsi unutuk mereset state data kosong type dri interface plugin vue-eternal-loading */
          noMore()
        } else {
          /** menggunakan fungsi unutuk load state type dri interface plugin vue-eternal-loading */
          loaded()
        }
      })
    }

    const processScanResi = (guid: any) => {
      departureProcessUseCase.changeStatusDelivery({
        Guid: guid,
      }).then((response) => {
        if (!response.error) {
          $toast.add({
            severity: 'success',
            detail: response.result.detail ?? response.result.Detail,
            group: 'bc',
            life: 3000
          })
          showDialogDetail.value = false
          reloadData()
        } else {
          $toast.add({
            severity: 'error',
            detail: response.message,
            group: 'bc',
            life: 3000
          })
        }
      })
    }

    /** FUngsi untuk kirim query ke odata */
    const submitSearch = async (search: any = '') => {
      if (search.length === 36) {
        // processScanResi(search)
        // eslint-disable-next-line no-use-before-define
        showDetailResiDialog(search)
      }
    }

    const submitSearchReceipt = async (search: any = '') => {
      if (search !== '') {
        filters.search = `Contains(tolower(TransPengirimanHd/Resi), '${search.toLowerCase()}')`
      } else {
        filters.search = ''
      }
      reloadData()
    }

    const onPage = (val: any) => {
      filters.skip = val.page * filters.top
      getAllReadyDeliver()
    }

    /** Fungsi untuk single assign courier */
    const changeCourier = async (kurirId: any, transId: any, index: any) => {
      const data = {
        KurirMsUserId: kurirId,
        Paket: [{ IdPaket: transId }]
      }
      const response = await newPackageUseCase.changeMultipleCourier(data)
      if (!response.error) {
        $toast.add({
          severity: 'success',
          detail: response.result.detail ?? response.result.Detail,
          group: 'bc',
          life: 1500
        })
        // reloadData()
      } else {
        $toast.add({
          severity: 'error',
          detail: response.message,
          group: 'bc',
          life: 1500
        })
      }
    }

    const settingSchedule = (dataSelected: any) => {
      const encrypt = encryptDecriptMethods.encrypt(dataSelected.IdPaket)
      // console.log('data selected', dataSelected)
      // dataForm.value = dataSelected
      // showDialogDetail.value = true
      router.push({
        name: 'scheduling-departure-package-step-1',
        params: {
          id: encrypt
        }
      })
    }

    const toManifest = (dataSelected: any) => {
      console.log('data', dataSelected.Id)
      departureProcessUseCase.toManifest(dataSelected.Id).then((response) => {
        if (!response.error) {
          reloadData()
          $toast.add({
            severity: 'success',
            detail: response.result.detail ?? response.result.Detail,
            group: 'bc',
            life: 3000
          })
        } else {
          $toast.add({
            severity: 'error',
            detail: response.message,
            group: 'bc',
            life: 3000
          })
        }
      })
    }

    const hideDialog = () => {
      showDialogDetail.value = false
    }

    /** Fungsi watch untuk monitoring value dri variable orderBy */
    watch(orderBy, async (newVal: any) => {
      // if (newVal) {
      await handleIsCheckInTempData()
      // filters.skip = 0
      filters.sorting = await newVal === null ? '' : newVal
      reloadData()
    })

    /** First load page */
    onMounted(() => {
      // getAllReadyDeliver()
      $socketHub.on('reloadDataScheduling', reloadData)
      showDialogDetail.value = false
    })

    onBeforeUnmount(() => {
      $socketHub.off('reloadDataScheduling')
    })

    const showDetailResiDialog = async (val: any) => {
      console.log('anu', val)
      // eslint-disable-next-line no-use-before-define
      await getDetailResiDialogData(val)
      console.log('dataSourceModal', dataSourceModal)
      showDialogDetail.value = true
      // dataSourceModal.value = val
    }

    const getDetailResiDialogData = async (id: any) => {
      await departureProcessUseCase.getDetailResi(id).then((response) => {
        if (!response.error) {
          response.result.map((x: any) => {
            x.SumBiayaTambahan = x.TransPengirimanHdBiayaTambahans.reduce((a: any, b: any) => a + (b.Nominal || 0), 0)
            return x
          })
          dataSourceModal.value = response.result[0]
          console.log('ddd')
        }
      })
    }

    return {
      inputSearch,
      submitSearch,
      orderBy,
      dataSource,
      filters,
      onPage,
      store,
      noResult,
      getAllReadyDeliver,
      orderOption,
      reloadData,
      changeCourier,
      loadData,
      isInitial,
      moment,
      listSchedule,
      showDialogDetail,
      settingSchedule,
      dataForm,
      hideDialog,
      toManifest,
      submitSearchReceipt,
      inputSearchReceipt,
      showDetailResiDialog,
      dataSourceModal,
      getDetailResiDialogData,
      processScanResi
    }
  }
}
